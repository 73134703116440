// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('@rails/ujs').start();
require('@rails/activestorage').start();
require('turbolinks').start();
require('channels');
require('jquery');
window.$ = $;
window.jQuery = $;
require('./_add/croppie.js');

import './person/ui.js';
import './person/form.js';
import './person/assessment.js';
import './person/chart.js';
import './functions.js';
import './_add/exif.js';
import './vue.js';
import './_add/jquery-ui.min.js';
import './_add/html2canvas.min.js';
import './person/canvas-chart.js';
import './web_push.js';

//オートコンプリートに使用
import './_add/jquery-ui.min.js';
import ClipboardJS from './_add/clipboard.min.js';
window.ClipboardJS = ClipboardJS;
import EXIF from './_add/exif.js';
window.EXIF = EXIF;
import Swiper from './_add//swiper-bundle.min.js';
window.Swiper = Swiper;
import html2canvas from './_add//html2canvas.min.js';
window.html2canvas = html2canvas;

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
