<template>
  <div>
    <div class="cross-assessment-sabject pt25">
      <div class="drop-down recruit">
        <div class="heading" @click="listIsShow = !listIsShow">
          <span class="fw-b c-yellow">適性検査タイトル・職種</span>を指定する
          <span class="counter" v-if="checkedRecruitIds.length > 0">{{checkedRecruitIds.length}}</span>
        </div>
        <ul class="body" v-if="listIsShow">
          <li class="item" v-for="(recruit) in recruits" :key="recruit.id" :class="{checked: checkedRecruitIds.includes(recruit.id)}">
            <label>
              <input type="checkbox" @change="onCheck('recruit', $event)" :value="recruit.id" :checked="checkedRecruitIds.includes(recruit.id)">
              <div class="inner">
                <span class="checkbox">
                  <svg class="checkmark" width="12px" height="9px" viewBox="0 0 12 9">
                    <polyline points="2 5 5 8 11 1"></polyline>
                  </svg>
                </span>
                <div>
                  <p class="fz75 fw-b lh120">{{recruit.assessment_title}}</p>
                  <p class="fz85 fw-b lh120 c-yellow">{{recruit.job.name || "職種：(未登録)"}}</p>
                </div>
              </div>
            </label>
          </li>
        </ul>
      </div>
      <div class="drop-down user">
        <div class="heading" @click="listIsShow = !listIsShow">
          <span class="fw-b c-key">人材</span>を指定する
          <span class="counter" v-if="checkedUserIds.length > 0">{{checkedUserIds.length}}</span>
        </div>
        <ul class="body" v-if="listIsShow">
          <li class="item" v-for="(user) in users" :key="user.id" :class="{checked: checkedUserIds.includes(user.id)}">
            <label>
              <input type="checkbox" @change="onCheck('user', $event)" :value="user.id" :checked="checkedUserIds.includes(user.id)">
              <div class="inner">
                <span class="checkbox">
                  <svg class="checkmark" width="12px" height="9px" viewBox="0 0 12 9">
                    <polyline points="2 5 5 8 11 1"></polyline>
                  </svg>
                </span>
                <div>
                  <p class="fz85 fw-b lh120 c-key">{{user.name}}</p>
                </div>
              </div>
            </label>
          </li>
        </ul>
      </div>
    </div>
    <p class="ta-c pt30 fw-b" v-if="checkedRecruitIds.length === 0 && checkedUserIds.length === 0">条件が指定されていません</p>
    <div class="loading-icon" v-else-if="dataFetching">
      <img src="/img/base/loader_black.svg" width="32" height="32" alt="計算中">
    </div>
    <div class="mt40" v-else>
      <div class="assessments-counter">
        <p class="fw-b"><span class="fz160">{{crossAssessments.length}}</span>件が見つかりました</p>
        <div class="reset-button" @click="resetSelect">条件をリセット</div>
      </div>
      <div class="list-table mt30">
        <div class="list-table-row head">
          <div class="sort-button" :class="sortState.created_at">
            <span class="label">応募日</span>
            <ul class="sort-by">
              <li @click="sortAssessments('created_at', 'asc')">応募日:昇順</li>
              <li @click="sortAssessments('created_at', 'desc')">応募日:降順</li>
            </ul>
          </div>
          <div class="sort-button" :class="sortState.recruit_id">
            <span class="label">適性検査タイトル・職種</span>
            <ul class="sort-by">
              <li @click="sortAssessments('recruit_id', 'asc')">求人・職種:昇順</li>
              <li @click="sortAssessments('recruit_id', 'desc')">求人・職種:降順</li>
            </ul>
          </div>
          <div class="sort-button" :class="sortState.name">
            <span class="label">人材</span>
            <ul class="sort-by">
              <li @click="sortAssessments('name', 'asc')">人材:昇順</li>
              <li @click="sortAssessments('name', 'desc')">人材:降順</li>
            </ul>
          </div>
          <div class="sort-button" :class="sortState.total_percent">
            <span class="label">マッチ度</span>
            <ul class="sort-by">
              <li @click="sortAssessments('total_percent', 'asc')">マッチ度:昇順</li>
              <li @click="sortAssessments('total_percent', 'desc')">マッチ度:降順</li>
            </ul>
          </div>
        </div>
        <div class="list-table-row" v-for="assessment in crossAssessments" :key="assessment.id">
          <div class="box">
            <div class="box_in">
              <p class="fz70 fw-b">{{formatDate(new Date(assessment.created_at))}}</p>
            </div>
          </div>
          <div class="box">
            <div class="box_in">
              <p class="fz70 fw-b">{{assessment.recruit.assessment_title}}</p>
              <p class="fz105 fw-b c-yellow">{{assessment.recruit.job.name || "職種：(未登録)"}}</p>
            </div>
          </div>
          <div class="box">
            <div class="box_in">
              <p class="fw-b c-key fz115">{{assessment.name}}</p>
            </div>
          </div>
          <div class="box">
            <div class="box_in">
              <p class="fw-b ff-logo fz115" v-if="assessment.status === 'activated'">{{assessment.total_percent}}%</p>
              <p class="match-rate-status -green" v-if="assessment.status === 'completed'">開封する</p>
              <p class="match-rate-status -gray" v-if="assessment.status === 'incompleted'">診断中</p>
            </div>
          </div>
          <div class="box">
            <div class="box_in">
              <p class="d-if jc-c ai-c fz85 fw-b lh100 bgc-black20 bdrs5 bd-black50 p5 hov ta-c">適性検査レポートへ</p>
            </div>
          </div>
          <a class="top-link" :href="rowLink(assessment)" :target="rowLink(assessment) === 'javascript:void(0)' ? '' : '_blank'"></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    propRecruits: Array,
    propUsers: Array,
  },
  data() {
    return {
      paramsCau: Number((new URLSearchParams(document.location.search)).get('cau')),
      paramsCac: Number((new URLSearchParams(document.location.search)).get('cac')),
      recruits: [...this.propRecruits],
      users: [...this.propUsers],
      crossAssessments: [],
      dataFetching: false,
      listIsShow: false,

      checkedRecruitIds: [],
      checkedUserIds: [],
      sortState: {
        created_at: null,
        recruit_id: null,
        name: null,
        total_percent: null,
      }
    }
  },
  created() {
    if (this.paramsCau && this.recruits.map(i => i.id).includes(this.paramsCau)) {
      this.checkedRecruitIds.push(this.paramsCau);
      this.checkedUserIds = this.users.map(i => i.id);
      this.fetchCrossAssessment();
    }
    if (this.paramsCac && this.users.map(i => i.id).includes(this.paramsCac)) {
      this.checkedUserIds.push(this.paramsCac);
      this.checkedRecruitIds = this.recruits.map(i => i.id);
      this.fetchCrossAssessment();
    }
    if (sessionStorage.getItem('crossAssessmentSession') && !(this.paramsCau) && !(this.paramsCac)) {
      const {checkedRecruitIds, checkedUserIds, sortState} = JSON.parse(sessionStorage.getItem('crossAssessmentSession'));
      this.checkedRecruitIds = checkedRecruitIds;
      this.checkedUserIds = checkedUserIds;
      this.sortState = sortState;
      this.fetchCrossAssessment();
    }
  },
  methods: {
    async fetchCrossAssessment() {
      this.dataFetching = true;
      try {
        const paramsObj = {
          c: this.checkedRecruitIds.length ? this.checkedRecruitIds : this.recruits.map(i => i.id),
          u: this.checkedUserIds.length ? this.checkedUserIds : this.users.map(i => i.id)
        };
        const query = new URLSearchParams(paramsObj);
        const res = await fetch(`/company/ajax/cross_assessments/?${query}`);
        const data = await res.json();
        const obj = await JSON.parse(data.cross_assessments, (k, v) => {
          if (k === 'total_percent') {
            return Number(v);
          }
          return v;
        });
        this.crossAssessments = obj;
        const sortInfo = Object.entries(this.sortState).find(item => !(item.includes(null)));
        if (sortInfo) {
          this.sortAssessments(sortInfo[0], sortInfo[1]);
        }
        this.dataFetching = false;
      } catch {
        location.reload();
      }
    },

    onCheck(subject, event) {
      const subjectId = Number(event.target.value);
      const checkedSubjectIds = this[`checked${subject.substr(0, 1).toUpperCase()}${subject.substr(1)}Ids`];
      if (checkedSubjectIds.includes(subjectId)) {
        this[`checked${subject.substr(0, 1).toUpperCase()}${subject.substr(1)}Ids`] = checkedSubjectIds.filter(id => id !== subjectId);
      } else {
        checkedSubjectIds.push(subjectId);
      }
      if (this.checkedRecruitIds.length || this.checkedUserIds.length) {
        this.fetchCrossAssessment().then(() => {
          this.createSessionObject();
        });
      } else {
        this.createSessionObject();
      }
    },

    onSideQuery() {

    },

    resetSelect() {
      if (this.paramsCau || this.paramsCac) {
        document.location.replace('/company/cross_assessments');
      }
      this.checkedRecruitIds = [];
      this.checkedUserIds = [];
      this.crossAssessments = [];
      for (const key in this.sortState) {
        this.sortState[key] = null;
      }
      this.createSessionObject();
    },

    formatDate(date) {
      return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
    },

    rowLink(assessment) {
      if (assessment.status !== 'incompleted') {
        return `/company/${assessment.recruit_id}/report_details/${assessment.assessment_id}`;
      } else {
        return 'javascript:void(0)';
      }
    },

    sortAssessments(sortTarget, order) {
      for (const key in this.sortState) {
        this.sortState[key] = null;
      }
      this.sortState[sortTarget] = order;
      if (order === 'asc') {
        this.crossAssessments.sort((a, b) => (a[`${sortTarget}`] < b[`${sortTarget}`]) ? -1 : 1);
      } else {
        this.crossAssessments.sort((a, b) => (a[`${sortTarget}`] > b[`${sortTarget}`]) ? -1 : 1);
      }
      this.createSessionObject();
    },

    createSessionObject() {
      const {checkedRecruitIds, checkedUserIds, sortState} = this.$data;
      sessionStorage.setItem('crossAssessmentSession', JSON.stringify(
        {
          checkedRecruitIds: checkedRecruitIds || [],
          checkedUserIds: checkedUserIds || [],
          sortState: sortState || {},
        }
      ));
    }
  }
}
</script>
