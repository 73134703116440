<template>
  <section class="mt40">
    <div class="d-f jc-sb">
      <h3 class="fz120 sm-fz140 fw-b lh140">この職種を表す写真</h3>
      <a href="javascript:void(0)" class="slide-modal-tgl d-if ai-c bd-black100 bdrs-max plr10 lh100 hov" data-modal="point3"><i class="icon-point">会社写真撮影のポイント</i></a>
    </div>
    <div class="cols nega gutter-10">
      <div class="col-4 mt10" v-for="num in numbers" :key="num">
        <div class="add-item-block-wrap-v">
          <label class="add-item-block company-image-cropped-image-wrap">
            <input type="file" @change="setFile(num, $event)" accept="image/*">
            <img v-if="!!currentImages[num]"
              :src="fetchBlobUrl(currentImages[num].signed_id, currentImages[num].blob.filename)"
              alt="" width="670" height="358"
              class="img-rspsv-full"
            >
          </label>
          <div
            v-if="currentImages[num]"
            class="my-delete-btn icon-add hov"
            @click="deleteImage(num, $event)"
          >
          </div>
        </div>
      </div>
    </div>

  <div class="modal-wrap" data-modal="image-cropper" style="display: none;">
    <div class="modal-wrap-view">
      <!-- .modal -->
      <div class="modal">
        <div class="ptb30 plr20">
          <vue-croppie v-if="width && height"
            ref="croppieRef"
            :viewport="{width: width, height: height}"
            :boundary="{width: width+50, height: height+50}"
            :enableResize="false"
            @update="update">
          </vue-croppie>
          <div class="mt15 ta-c">
            <button @click="crop" class="my-crop-btn basic-btn d-ib sm-d-b bgc-key ff-logo fz120 sm-fz140 c-white hov">切り取る</button>
          </div>
        </div>
      </div>
      <!-- .black-back -->
      <div class="black-back"></div>
    </div>
  </div>
  </section>
</template>

<script>
import { BLOB_URL, ENDPOINT } from '../utils/endpoint.js'
import { METHOD } from '../utils/method.js'
import { api, apiFormData } from '../packs/company/ajax.js'

export default {
  props: {
    propImages: {
      type: Array,
    },
    recruitId: Number
  },
  data: () => {
    return {
      currentImages: {},
      file: null,
      fileObjectURL: null,
      point: { x1: null, x2: null, y1: null, y2: null },
      width: null,
      height: null,
      numbers: ["1", "2", "3"],
      num: null,
    }
  },
  created() {
    this.numbers.forEach(num => {
      const images = this.propImages.filter((image) => {
        return image.name === `image${num}`
      })
      this.$set(this.currentImages, num, images[0])
    })
  },
  mounted() {
    this.width = $('.company-image-cropped-image-wrap')[0].offsetWidth;
    this.height = $('.company-image-cropped-image-wrap')[0].offsetHeight;
  },
  methods: {
    fetchBlobUrl(signed_id, filename) {
      return BLOB_URL(signed_id, filename)
    },
    setFile(num, e) {
      this.num = num
      e.preventDefault();
      this.file = e.target.files[0];
      var reader = new FileReader()
      reader.onload = (e) => {
        this.fileObjectURL = e.target.result
        this.$refs.croppieRef.bind({
          url: e.target.result,
        });
      }
      reader.readAsDataURL(this.file);
      $('.modal-wrap[data-modal="image-cropper"]').fadeIn(200);
    },
    crop(e) {
      e.preventDefault();
      if (this.posting) { return }
      this.posting = true;
      const { file, point, num } = this.$data;
      const params = { file: file, recruitId: this.recruitId , ...point }
      apiFormData(METHOD.PUT, ENDPOINT.COMPANY.IMAGES_PATH(this.num), params).done(res => {
        this.$set(this.currentImages, num, res)
      }).always(() => {
        $('.modal-wrap[data-modal="image-cropper"]').fadeOut(200);
        this.posting = false
      })
    },
    update(val) {
      this.point.x1 = val.points[0]
      this.point.x2 = val.points[2]
      this.point.y1 = val.points[1]
      this.point.y2 = val.points[3]
    },
    deleteImage(num, e) {
      // TODO
      e.preventDefault();
      api(METHOD.DELETE, ENDPOINT.COMPANY.IMAGES_PATH(num), {}).done(res => {
        this.$set(this.currentImages, num, null)
      })
    },
  }
}
</script>
